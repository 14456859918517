<template>
  <div class="flex items-center gap-8">
    <div class="flex-1 border-bgr-300 border-t"></div>
    <div class="text-bgr-300 uppercase text-sm">{{ $t(`${trPrefix}or`) }}</div>
    <div class="flex-1 border-bgr-300 border-t"></div>
  </div>
</template>

<script lang="ts" setup>
const trPrefix = 'guestWorld.components.WebccOrDivider.'
</script>

<style></style>
